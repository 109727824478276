import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import logoPath from "../../images/logo.png";
import ScrollToTop from "../ScrollToTop";
import ServiceMenuItems from "../ServiceMenuItems";

const Footer = () => (
  <footer>
    <div className="footer-wrapper">
      <div className="footer-content-wrapper">
        <Container>
          <Row>
            <Col sm={6} lg={3}>
              <div className="footer-widget">
                <div className="footer-logo">
                  <a href="/" aria-label="Отиди на начална страница">
                    <img
                      src={logoPath}
                      alt="Лого АТКОМ ЕООД"
                      title="Лого АТКОМ ЕООД"
                    />
                  </a>
                </div>

                <div className="footer-widget-about">
                  <p>
                    Цялостни решения в проектирането и изграждането на системи
                    за сигурност от ново поколение - видеонаблюдение,
                    пожароизвестяване, електроинсталация, контрол на достъпа и
                    мрежово оборудване.
                  </p>

                  <a href="/about-us">
                    <span>Прочети повече</span>{" "}
                    <FontAwesomeIcon icon={faAngleRight} size={`lg`} />
                  </a>
                </div>
              </div>
            </Col>

            <Col sm={6} lg={3} className="d-flex justify-content-lg-center">
              <div className="footer-widget">
                <h4 className="footer-widget-title">Връзки</h4>
                <ul className="footer-widget-navigation">
                  <li>
                    <a href="/">Начало</a>
                  </li>
                  <li>
                    <a href="/about-us">За Нас</a>
                  </li>
                  <li>
                    <a href="/projects">Проекти</a>
                  </li>
                  {/* <li>
                    <a href="/blog">Блог</a>
                  </li> */}
                  <li>
                    <a href="/contact">Контакти</a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col sm={6} lg={3} className="d-flex justify-content-lg-center">
              <div className="footer-widget">
                <h4 className="footer-widget-title">Услуги</h4>
                <ServiceMenuItems className="footer-widget-navigation" />
              </div>
            </Col>

            <Col sm={6} lg={3} className="d-flex justify-content-lg-center">
              <div className="footer-widget">
                <h4 className="footer-widget-title">Контакти</h4>
                <div className="footer-widget-contact">
                  <p className="address">
                    град Ямбол,
                    <br />
                    ул. Димитър Благоев 18
                  </p>
                  <ul className="contact-details">
                    <li>
                      <span>Т:</span>{" "}
                      <a href="tel:+359899777813">+3598 99 777 813</a>
                    </li>
                    <li>
                      <span>Е:</span>{" "}
                      <a href="mailto:atkom@abv.bg">atkom@abv.bg</a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="footer-copyright-wrapper">
        <Container className="footer-copyright text-center">
          <span>
            ©2019 ATKOM ЕООД. Всички права запазени. Дизайн и изработка от{" "}
          </span>
          <a
            href="https://bulcode.com"
            title="Web development - Symfony, Drupal, React"
          >
            Bulcode
          </a>
        </Container>
      </div>
    </div>

    <ScrollToTop />
  </footer>
);

export default Footer;
