import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import faviconPath from "../../images/favicon.png";
import logoPath from "../../images/logo.png";
import Footer from "../Footer";
import Header from "../Header";
import MobileMenu from "../MobileMenu";
import Preloader from "../Preloader";

const Layout = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>
          АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и
          електроинсталации
        </title>
        <link rel="icon" type="image/png" href={faviconPath} sizes="16x16" />
        <meta charset="utf-8" />
        <meta http-equiv="content-language" content="bg" />
        <meta name="MobileOptimized" content="width" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="title"
          content="АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации"
        />
        <meta
          name="description"
          content="АТКОМ ЕООД - изграждане и поддръжка на системи за видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации"
        />
        <meta
          property="og:title"
          content="АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации"
        />
        <meta
          property="og:site_name"
          content="АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации"
        />
        <meta property="og:image" content={logoPath} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://atkom-bg.com/" />
        <meta
          property="og:description"
          content="АТКОМ ЕООД - изграждане и поддръжка на системи за видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации"
        />
        <link rel="shortlink" href="https://atkom-bg.com/" />
        <link rel="canonical" href="https://atkom-bg.com/" />
      </Helmet>

      {loading && <Preloader />}
      <Header />
      {props.children}
      <Footer />
      <MobileMenu />
    </React.Fragment>
  );
};

export default Layout;
