import {
  faFireExtinguisher,
  faNetworkWired,
  faPlug,
  faVideo,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ServiceImage from "../ServiceImage";

const ServiceFull = (props) => {
  let icon;

  switch (props.data.machineName) {
    case "video":
      icon = faVideo;
      break;
    case "fire":
      icon = faFireExtinguisher;
      break;
    case "access":
      icon = faWarehouse;
      break;
    case "electricity":
      icon = faPlug;
      break;
    case "network":
      icon = faNetworkWired;
      break;
    default:
      icon = null;
  }

  return (
    <div className="service-full">
      <div className="image">
        <div className="image-wrapper">
          <ServiceImage service={props.data} />
        </div>

        <div className="icon">
          <FontAwesomeIcon icon={icon} />
        </div>
      </div>

      <div className="content">
        <h2 className="title">{props.data.title}</h2>

        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: props.data.description }}
        />
      </div>
    </div>
  );
};

export default ServiceFull;
