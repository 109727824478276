import {
  faFireExtinguisher,
  faNetworkWired,
  faPlug,
  faVideo,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const ServiceGridIconTeaser = (props) => {
  let icon;

  switch (props.data.machineName) {
    case "video":
      icon = faVideo;
      break;
    case "fire":
      icon = faFireExtinguisher;
      break;
    case "access":
      icon = faWarehouse;
      break;
    case "electricity":
      icon = faPlug;
      break;
    case "network":
      icon = faNetworkWired;
      break;
    default:
      icon = null;
      break;
  }

  return (
    <div className="col-lg-4 col-md-6 col-12 section-space--bottom--30">
      <div className="feature">
        <div className="icon">
          <FontAwesomeIcon icon={icon} />
        </div>

        <div className="content">
          <h3 className="title">
            <a href={`/service/${props.data.slug}`}>{props.data.title}</a>
          </h3>

          <p className="subtitle">{props.data.subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceGridIconTeaser;
