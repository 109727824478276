import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const TestimonialsTeaser = (props) => (
  <div className="testimonials-slider-item text-center">
    {props.data.image ? (
      <div className="image">
        <img
          src={`${props.data.image.webPath}`}
          className="img-fluid"
          alt={props.data.name}
          title={props.data.name}
        />
      </div>
    ) : null}

    <p className="author-spec">
      <strong>{props.data.name},</strong> <span>{props.data.customer}</span>
    </p>

    <div className="author-text">
      <FontAwesomeIcon icon={faQuoteRight} />

      <p>{props.data.quote}</p>
    </div>
  </div>
);

export default TestimonialsTeaser;
