import React from "react";
import image from "../../images/video-cta.jpg";

const AboutUsBlock = () => {
  // const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="video-cta-area section-space--inner--120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="video-cta-content">
              <h4 className="video-cta-content__small-title">За Нас</h4>
              <h3 className="video-cta-content__title">
                Как стигнахме до тук?
              </h3>
              <p className="video-cta-content__text">
                Водени от идеята да предложим комплексен набор от услуги за
                нашите клиенти, АТКОМ се утвърди като лидер в предлагането на
                индивидуални решения за сигурност и безопасност.
              </p>
              <a href="/contact" className="btn btn-secondary">
                Свържете се с нас
              </a>
            </div>
          </div>

          <div className="col-lg-5 offset-lg-1 col-md-6">
            <div className="cta-video-image">
              <div className="video-popup">
                {/* <ModalVideo
                  youtube={{ autoplay: 1, loop: 1, modestbranding: 1 }}
                  isOpen={isOpen}
                  videoId="OrS-93U4AYQ"
                  onClose={() => setIsOpen(false)}
                /> */}

                {/* <button
                  onClick={() => setIsOpen(true)}
                  aria-label="Отвори видео"
                > */}
                <div className="cta-video-image__image">
                  <img
                    src={image}
                    className="img-fluid"
                    alt="За нас снимка"
                    title="За нас снимка"
                  />
                </div>

                {/* <div className="cta-video-image__icon">
                    <FontAwesomeIcon icon={faPlay} />
                  </div> */}
                {/* </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsBlock;
