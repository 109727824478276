import React from "react";
import { LightgalleryProvider } from "react-lightgallery";
import PhotoItem from "../PhotoItem";

const PhotoGallery = (props) => (
  <LightgalleryProvider>
    <div className="row row-5">
      {props.images.map((p, idx) => (
        <PhotoItem key={idx} image={p.webPath} group="group1" />
      ))}
    </div>
  </LightgalleryProvider>
);

export default PhotoGallery;
