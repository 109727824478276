import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import SwiperCore, { A11y, Autoplay, Navigation } from "swiper";
import "swiper/components/effect-fade/effect-fade.scss";
import "swiper/components/navigation/navigation.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import testimonials from "../../utils/testimonials.json";
import TestimonialsTeaser from "../TestimonialsTeaser";

// Install Swiper components.
SwiperCore.use([A11y, Autoplay, Navigation]);

const TestimonialsSlider = () => {
  return (
    <div className="testimonials-slider-area section-space--inner--120 bg-secondary">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <Swiper
              autoplay={{
                delay: 5000,
              }}
              centeredSlides={true}
              loop={true}
              navigation={{
                nextEl: ".ht-swiper-button-next",
                prevEl: ".ht-swiper-button-prev",
              }}
              slidesPerView={1}
              speed={1000}
              watchSlidesVisibility={true}
            >
              {testimonials.map((val) => (
                <SwiperSlide key={val.id}>
                  <TestimonialsTeaser data={val} />
                </SwiperSlide>
              ))}

              <div className="ht-swiper-button-prev ht-swiper-button-nav d-xl-block">
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>

              <div className="ht-swiper-button-next ht-swiper-button-nav d-xl-block">
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSlider;
