import React from "react";
import services from "../../utils/services.json";
import ServiceGridIconTeaser from "../ServiceGridIconTeaser";

const ServiceGridIcon = () => {
  return (
    <div className="feature-section section-space--inner--100">
      <div className="container">
        <div className="col-lg-12">
          <div className="feature-item-wrapper">
            <div className="row">
              {services.map((val, i) => (
                <ServiceGridIconTeaser key={i} data={val} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceGridIcon;
