import React from "react";
import { Helmet } from "react-helmet";
import logoPath from "../../images/logo.png";
import projects from "../../utils/projects.json";
import Layout from "../Layout";
import ProjectTeaser from "../ProjectTeaser";

const ProjectsPage = () => (
  <Layout>
    <Helmet>
      <title>
        АТКОМ ЕООД - нашите проекти в областта на видеонаблюдението,
        електроинсталциите и контрола на достъп
      </title>

      <meta
        name="description"
        content="АТКОМ ЕООД - запознайте се с нашата работа по изграждане и поддръжка на системи за видеонаблюдение, електроинсталции и контрол на достъп"
      />
      <meta
        property="og:title"
        content="АТКОМ ЕООД - нашите проекти в областта на видеонаблюдението, електроинсталциите и контрола на достъп"
      />
      <meta
        property="og:description"
        content="АТКОМ ЕООД - запознайте се с нашата работа по изграждане и поддръжка на системи за видеонаблюдение, електроинсталции и контрол на достъп"
      />
      <meta property="og:url" content="https://atkom-bg.com/projects/" />
      <link rel="shortlink" href="https://atkom-bg.com/projects/" />
      <link rel="canonical" href="https://atkom-bg.com/projects/" />

      <script type="application/ld+json">{`
          {
            "@context": "http://schema.org",
            "@graph": [
              {
                "@type": "Organization",
                "@id": "https://atkom-bg.com/",
                "name": "АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации",
                "description": "АТКОМ ЕООД - изграждане и поддръжка на системи за видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации",
                "slogan": "",
                "url": "https://atkom-bg.com/",
                "logo": {
                  "@type": "ImageObject",
                  "url": ${logoPath},
                  "width": 656,
                  "height": 176,
                  "caption": "АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации"
                }
              },
              {
                "@type": "WebPage",
                "url": "https://atkom-bg.com/projects/",
                "name": "АТКОМ ЕООД - нашите проекти в областта на видеонаблюдението, електроинсталциите и контрола на достъп"
              }
            ]
          }
        `}</script>
    </Helmet>

    <div className="breadcrumb-area breadcrumb-bg">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="page-banner text-center">
              <h1>Проекти</h1>

              <ul className="page-breadcrumb">
                <li>
                  <a href="/">Начало</a>
                </li>
                <li>Проекти</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="page-wrapper section-space--inner--120">
      <div className="project-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="project-item-wrapper">
                <div className="row">
                  {projects.map((val, i) => (
                    <ProjectTeaser key={i} data={val} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default ProjectsPage;
