import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import SwiperCore, { A11y, Autoplay, EffectFade, Navigation } from "swiper";
import "swiper/components/effect-fade/effect-fade.scss";
import "swiper/components/navigation/navigation.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import accessSlider from "../../images/access-slider.jpg";
import fireSlider from "../../images/fire-slider.jpg";
import videoSlider from "../../images/video-slider.jpg";

// Install Swiper components.
SwiperCore.use([A11y, Autoplay, EffectFade, Navigation]);

const Slider = () => {
  const data = [
    {
      bgImg: videoSlider,
      sliderTitle: "Видеонаблюдение",
      sliderSubtitle:
        "Избор и инсталиране на полезна и устойчива конфигурация за денонощно видеонаблюдение в частния и бизнес сектор.",
      btnLink: "contact",
      btnText: "Научете повече",
    },
    {
      bgImg: fireSlider,
      sliderTitle: "Пожароизвестяване",
      sliderSubtitle:
        "Изграждане на система за локализиране и известяване при наличие на пожар в административни и жилищни сгради.",
      btnLink: "contact",
      btnText: "Научете повече",
    },
    {
      bgImg: accessSlider,
      sliderTitle: "Контрол на достъп",
      sliderSubtitle:
        "Инсталация на системи за централизиран или самостоятелен контрол на достъпа в административни, жилищни и индустриални помещения.",
      btnLink: "contact",
      btnText: "Научете повече",
    },
    {
      bgImg: accessSlider,
      sliderTitle: "Електроинсталация",
      sliderSubtitle:
        "Цялостен набор от услуги за изграждане на безопасна електроинсталационна система за дома и офиса.",
      btnLink: "contact",
      btnText: "Научете повече",
    },
    {
      bgImg: accessSlider,
      sliderTitle: "Мрежово оборудване",
      sliderSubtitle:
        "Надеждност, достъпност и сигурност предлагат нашите комплексни решения за изграждане на комуникационни мрежи и системи.",
      btnLink: "contact",
      btnText: "Научете повече",
    },
  ];

  const slides = data.map((val, i) => {
    return (
      <SwiperSlide key={i}>
        <div
          className="hero-slider-item"
          style={{ backgroundImage: `url(${val.bgImg})` }}
        >
          <div className="hero-slider-content-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="hero-slider-content">
                    <h2 className="hero-slider-title">{val.sliderTitle}</h2>
                    <p className="hero-slider-text">{val.sliderSubtitle}</p>
                    <a
                      className="hero-slider-btn btn btn-secondary"
                      href={`/${val.btnLink}`}
                    >
                      {val.btnText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    );
  });

  return (
    <div className="hero-slider-area">
      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        centeredSlides={true}
        effect={"fade"}
        loop={true}
        navigation={{
          nextEl: ".ht-swiper-button-next",
          prevEl: ".ht-swiper-button-prev",
        }}
        slidesPerView={1}
        speed={1000}
      >
        {slides}

        <div className="ht-swiper-button-prev ht-swiper-button-nav d-xl-block">
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>

        <div className="ht-swiper-button-next ht-swiper-button-nav d-xl-block">
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </Swiper>
    </div>
  );
};

export default Slider;
