import React from "react";
import services from "../../utils/services.json";

const ServiceMenuItems = (props) => {
  return (
    <ul {...props}>
      {services.map((val, i) => (
        <li key={i}>
          <a href={`/service/${val.slug}`}>{val.title}</a>
        </li>
      ))}
    </ul>
  );
};

export default ServiceMenuItems;
