import React from "react";
import AccessImage from "../../images/access-service.jpg";
import ElectricityImage from "../../images/electricity-service.jpg";
import FireImage from "../../images/fire-service.jpg";
import NetworkImage from "../../images/network-service.jpg";
import VideoImage from "../../images/video-service.jpg";

const images = {
  access: AccessImage,
  electricity: ElectricityImage,
  fire: FireImage,
  network: NetworkImage,
  video: VideoImage,
};

const ServiceImage = ({ service, ...props }) => (
  <img
    src={images[service.machineName]}
    alt={service.title}
    title={service.title}
    className={"img-fluid"}
    {...props}
  />
);

export default ServiceImage;
