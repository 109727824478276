import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import services from "../../utils/services.json";
import ServiceGridTeaser from "../ServiceGridTeaser";

const ServiceGridSlider = () => {
  return (
    <div className="service-grid-slider-area section-space--inner--120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area text-center">
              <h2 className="section-title section-space--bottom--80">
                Нашите услуги
              </h2>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="service-slider">
              <Swiper
                autoplay={{
                  delay: 3000,
                }}
                breakpoints={{
                  1199: {
                    slidesPerView: 3,
                  },
                  991: {
                    slidesPerView: 2,
                  },
                  767: {
                    slidesPerView: 1,
                  },
                  575: {
                    slidesPerView: 1,
                  },
                }}
                loop={true}
                slidesPerView={3}
                spaceBetween={30}
                speed={1000}
                watchSlidesVisibility={true}
              >
                {services.map((val, i) => (
                  <SwiperSlide key={1}>
                    <ServiceGridTeaser data={val} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceGridSlider;
