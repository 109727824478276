import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Helmet } from "react-helmet";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Col, Container, Row } from "reactstrap";
import logoPath from "../../images/logo.png";
import services from "../../utils/services.json";
import Layout from "../Layout";
import ServiceFull from "../ServiceFull";

const ServicesPage = (props) => {
  let currentTabIndex = 0;
  if (props.match.params.slug) {
    const currentService = services.filter(
      (service) => service.slug === props.match.params.slug
    );

    if (currentService.length) {
      currentTabIndex = currentService[0].id - 1;
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>
          АТКОМ ЕООД - сигурност, контрол и безопастност за вашия дом, офис и
          земя
        </title>

        <meta
          name="description"
          content="АТКОМ ЕООД - услуги за монтаж и поддръжка на системи за сигурност, контрол на достъпа, електроинсталации и пожароизвестяване"
        />
        <meta
          property="og:title"
          content="АТКОМ ЕООД - сигурност, контрол и безопастност за вашия дом, офис и земя"
        />
        <meta
          property="og:description"
          content="АТКОМ ЕООД - услуги за монтаж и поддръжка на системи за сигурност, контрол на достъпа, електроинсталации и пожароизвестяване"
        />
        <meta property="og:url" content="https://atkom-bg.com/services/" />
        <link rel="shortlink" href="https://atkom-bg.com/services/" />
        <link rel="canonical" href="https://atkom-bg.com/services/" />

        <script type="application/ld+json">{`
          {
            "@context": "http://schema.org",
            "@graph": [
              {
                "@type": "Organization",
                "@id": "https://atkom-bg.com/",
                "name": "АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации",
                "description": "АТКОМ ЕООД - изграждане и поддръжка на системи за видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации",
                "slogan": "",
                "url": "https://atkom-bg.com/",
                "logo": {
                  "@type": "ImageObject",
                  "url": ${logoPath},
                  "width": 656,
                  "height": 176,
                  "caption": "АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации"
                }
              },
              {
                "@type": "WebPage",
                "url": "https://atkom-bg.com/services/",
                "name": "АТКОМ ЕООД - сигурност, контрол и безопастност за вашия дом, офис и земя"
              }
            ]
          }
        `}</script>
      </Helmet>

      <div className="breadcrumb-area breadcrumb-bg">
        <Container>
          <Row>
            <Col>
              <div className="page-banner text-center">
                <h1>Услуги</h1>

                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">Начало</a>
                  </li>
                  <li>Услуги</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="page-wrapper section-space--inner--120">
        <div className="service-section">
          <Container>
            <Row>
              <Col>
                <div className="service-item-wrapper">
                  <Tabs
                    className="services-tabs"
                    selectedTabClassName="services-tab-active"
                    selectedTabPanelClassName="services-tab-panel-active"
                    defaultIndex={currentTabIndex}
                  >
                    <Row>
                      <Col lg={3} className="services-sidebar">
                        <TabList className="services-tab-list section-space--bottom--50">
                          {services.map((val, i) => (
                            <Tab className="services-tab" key={i}>
                              {val.title}
                            </Tab>
                          ))}
                        </TabList>

                        <div className="cta-widget section-space--bottom--50">
                          <h6>Имате въпроси?</h6>

                          <div className="content">
                            <p className="call-us">
                              <span>Позвънете на:</span>
                              <span>
                                <FontAwesomeIcon icon={faPhone} />
                                <a href="tel:+359899777813">+3598 99 777 813</a>
                              </span>
                            </p>

                            <p className="message-us">
                              <span>Или</span>
                              <a
                                aria-current="page"
                                className="btn btn-secondary btn-sm btn-icon icon-left active"
                                href="mailto:atkom@abv.bg"
                              >
                                <FontAwesomeIcon icon={faEnvelope} /> Изпрате
                                съобщение
                              </a>
                            </p>
                          </div>
                        </div>
                      </Col>

                      <Col lg={9}>
                        {services.map((val, i) => (
                          <TabPanel className="services-tab-panel" key={i}>
                            <ServiceFull key={i} data={val} />
                          </TabPanel>
                        ))}
                      </Col>
                    </Row>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default ServicesPage;
