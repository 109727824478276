import React from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import logoPath from "../../images/logo.png";
import projects from "../../utils/projects.json";
import Layout from "../Layout";
import PhotoGallery from "../PhotoGallery";

const ProjectPage = (props) => {
  const project = projects
    .filter((project) => project.slug === props.match.params.slug)
    .shift();

  if (!project) {
    return <Redirect to="/not-found" />;
  }

  return (
    <Layout>
      <Helmet>
        <title>АТКОМ ЕООД - {project.title}</title>

        <meta
          name="description"
          content={project.description.substring(0, 150)}
        />
        <meta property="og:title" content={`АТКОМ ЕООД - ${project.title}`} />
        <meta
          property="og:description"
          content={project.description.substring(0, 150)}
        />
        <meta
          property="og:url"
          content={`https://atkom-bg.com/project/${project.slug}/`}
        />
        <link
          rel="shortlink"
          href={`https://atkom-bg.com/project/${project.slug}/`}
        />
        <link
          rel="canonical"
          href={`https://atkom-bg.com/project/${project.slug}/`}
        />

        <script type="application/ld+json">{`
          {
            "@context": "http://schema.org",
            "@graph": [
              {
                "@type": "Organization",
                "@id": "https://atkom-bg.com/",
                "name": "АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации",
                "description": "АТКОМ ЕООД - изграждане и поддръжка на системи за видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации",
                "slogan": "",
                "url": "https://atkom-bg.com/",
                "logo": {
                  "@type": "ImageObject",
                  "url": ${logoPath},
                  "width": 656,
                  "height": 176,
                  "caption": "АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации"
                }
              },
              {
                "@type": "WebPage",
                "url": "https://atkom-bg.com/project/${project.slug}/",
                "name": "АТКОМ ЕООД - ${project.title}"
              }
            ]
          }
        `}</script>
      </Helmet>

      <div className="breadcrumb-area breadcrumb-bg">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="page-banner text-center">
                <h1>{project.title}</h1>

                <ul className="page-breadcrumb">
                  <li>
                    <a href="/">Начало</a>
                  </li>
                  <li>
                    <a href="/projects">Проекти</a>
                  </li>
                  <li>{project.title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-wrapper section-space--inner--120">
        <div className="project-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 section-space--bottom--30">
                <div className="project-details">
                  <h2>{project.title}</h2>
                  <p>{project.description}</p>
                </div>
              </div>

              <div className="col-lg-5 offset-lg-1 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                <div className="project-information">
                  <h6>Информация за проекта</h6>

                  <ul>
                    <li>
                      <span>Клиент:</span> <span>Климент Охридски</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12">
                <PhotoGallery images={project.image} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProjectPage;
