import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import { Container } from "reactstrap";
import logoPath from "../../images/logo.png";
import ServiceMenuItems from "../ServiceMenuItems";

const NavBar = () => {
  const mobileMenuButtonRef = useRef(null);

  useEffect(() => {
    const mobileMenuButton = mobileMenuButtonRef.current;
    const clickEvent = () => {
      document.body.classList.add("no-overflow");
      document.getElementById("mobile-menu-overlay").classList.add("active");
    };

    mobileMenuButton.addEventListener("click", clickEvent);

    return () => mobileMenuButton.removeEventListener("click", clickEvent);
  }, []);

  return (
    <div className="header-info-area">
      <Container>
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo">
            <a href="/" aria-label="Отиди на начална страница">
              <img
                src={logoPath}
                alt="Лого АТКОМ ЕООД"
                title="Лого АТКОМ ЕООД"
              />
            </a>
          </div>

          <div ref={mobileMenuButtonRef} className="mobile-navigation-icon">
            <i />
          </div>

          <nav className="header-navigation">
            <ul>
              <li>
                <a href="/">Начало</a>
              </li>

              <li>
                <a href="/about-us">За Нас</a>
              </li>

              <li className="has-children">
                <a href="/services">
                  <span>Услуги</span>
                  <FontAwesomeIcon icon={faChevronDown} size="xs" />
                </a>

                <ServiceMenuItems className="submenu" />
              </li>

              <li>
                <a href="/projects">
                  <span>Проекти</span>
                </a>
              </li>

              {/* <li>
                <a href="/blog">
                  <span>Блог</span>
                </a>
              </li> */}

              <li>
                <a href="/contact">Контакти</a>
              </li>
            </ul>
          </nav>
        </div>
      </Container>
    </div>
  );
};

export default NavBar;
