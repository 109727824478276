import React from "react";
import projects from "../../utils/projects.json";
import ProjectTeaser from "../ProjectTeaser";

const FeaturedProjectsGrid = () => (
  <div className="fetured-projects-grid-area section-space--inner--120">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="section-title-area text-center">
            <h2 className="section-title section-space--bottom--80">
              Избрани проекти
            </h2>
          </div>
        </div>

        <div className="col-12">
          <div className="project-item-wrapper">
            <div className="row">
              {projects.map((val, i) => (
                <ProjectTeaser key={i} data={val} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FeaturedProjectsGrid;
