import {
  faAddressBook,
  faEnvelope,
  faPhone,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import ServiceMenuItems from "../ServiceMenuItems";

const MobileMenu = () => {
  const [expanded, toggleChildrenExpand] = useState({ services: false });
  const mobileMenuCloseButtonRef = useRef(null);

  useEffect(() => {
    const closeButtonRef = mobileMenuCloseButtonRef.current;
    const clickEvent = () => {
      document.body.classList.remove("no-overflow");
      document.getElementById("mobile-menu-overlay").classList.remove("active");
    };

    closeButtonRef.addEventListener("click", clickEvent);

    return () => closeButtonRef.removeEventListener("click", clickEvent);
  }, []);

  return (
    <div className="offcanvas-mobile-menu" id="mobile-menu-overlay">
      <button
        ref={mobileMenuCloseButtonRef}
        className="offcanvas-menu-close"
        aria-label="Затвори менюто"
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>

      <div className="offcanvas-wrapper">
        <div className="offcanvas-inner-content">
          <nav className="offcanvas-navigation">
            <ul>
              <li>
                <a href="/">Начало</a>
              </li>

              <li>
                <a href="/about-us">За Нас</a>
              </li>

              <li
                className={
                  "menu-item-has-children" +
                  (expanded.services ? " active" : "")
                }
              >
                <a href="/services">Услуги</a>

                <span
                  className="menu-expand"
                  onClick={() =>
                    toggleChildrenExpand({
                      ...expanded,
                      services: !expanded.services,
                    })
                  }
                >
                  <i />
                </span>

                <CSSTransition
                  in={expanded.services}
                  timeout={300}
                  classNames="fade"
                  unmountOnExit
                >
                  <ServiceMenuItems key="services" className="sub-menu" />
                </CSSTransition>
              </li>

              <li>
                <a href="/projects">Проекти</a>
              </li>

              {/* <li>
                <a href="/blog">Блог</a>
              </li> */}

              <li>
                <a href="/contact">Контакти</a>
              </li>
            </ul>
          </nav>

          <div className="off-canvas-contact">
            <ul>
              <li>
                <FontAwesomeIcon icon={faEnvelope} />
                <a href="mailto:atkom@abv.bg">atkom@abv.bg</a>
              </li>

              <li>
                <FontAwesomeIcon icon={faPhone} />
                <a href="tel:+359899777813">+3598 99 777 813</a>
              </li>

              <li>
                <FontAwesomeIcon icon={faAddressBook} />
                <span>Ямбол, ул. Д. Благоев 18</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
