import React from "react";
import { LightgalleryItem } from "react-lightgallery";

const PhotoItem = (props) => (
  <div className="col-xl-3 col-lg-4 col-sm-6 col-12 section-space--top--10">
    <LightgalleryItem group={props.group} src={`/${props.image}`}>
      <button
        className="gallery-item single-gallery-thumb"
        aria-label="Отвори снимката в модал"
      >
        <img
          src={`/${props.image}`}
          className="img-fluid"
          alt="Снимка на проект"
          title="Снимка на проект"
        />
        <span className="plus" />
      </button>
    </LightgalleryItem>
  </div>
);

export default PhotoItem;
