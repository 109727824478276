import {
  faAddressBook,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "reactstrap";

const TopBar = () => (
  <div className="header-top-bar bg-primary d-none d-lg-block">
    <Container>
      <Row className="align-items-center">
        <Col lg="auto" className="mr-auto">
          <ul className="d-flex flex-row flex-wrap">
            <li>
              <FontAwesomeIcon icon={faEnvelope} />
              <a href="mailto:atkom@abv.bg">atkom@abv.bg</a>
            </li>
            <li>
              <FontAwesomeIcon icon={faPhone} />
              <a href="tel:+359899777813">+3598 99 777 813</a>
            </li>
            <li>
              <FontAwesomeIcon icon={faAddressBook} />
              <span>Ямбол, ул. Д. Благоев 18</span>
            </li>
          </ul>
        </Col>

        <Col className="col-auto">
          <a href="mailto:atkom@abv.bg" className="btn btn-secondary">
            Изпрати запитване
          </a>
        </Col>
      </Row>
    </Container>
  </div>
);

export default TopBar;
