import React from "react";

const ProjectTeaser = (props) => (
  <div className="col-lg-4 col-sm-6 section-space--bottom--30">
    <div className="service-grid-item service-grid-item--style2">
      {props.data.image.length ? (
        <div className="service-grid-item__image">
          <div className="service-grid-item__image-wrapper">
            <a href={`/project/${props.data.slug}`}>
              <img
                src={`${props.data.image[0].webPath}`}
                className="img-fluid"
                alt={props.data.title}
                title={props.data.title}
              />
            </a>
          </div>
        </div>
      ) : null}

      <div className="service-grid-item__content">
        <h3 className="title">
          <a href={`/project/${props.data.slug}`}>{props.data.title}</a>
        </h3>

        <p className="subtitle">{props.data.subtitle}</p>
        <a href={`/project/${props.data.slug}`} className="see-more-link">
          Прочети повече
        </a>
      </div>
    </div>
  </div>
);

export default ProjectTeaser;
