import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Layout from "../Layout";

const NotFound = () => (
  <Layout>
    <Helmet>
      <title>404 - Страницата не е намерена - АТКОМ ЕООД</title>
      <meta name="description" content="Страницата не е намерена!" />
    </Helmet>

    <div className="error-page-wrapper d-flex align-items-center section-space--inner--100">
      <div className="container">
        <div className="row">
          <div className="col-md-9 m-auto text-center">
            <div className="error-content-centered d-flex align-items-center justfy-content-center">
              <div className="error-page-content-wrap">
                <h1>404</h1>
                <h2>Съжаляваме, но не можахме да намерим тази страница</h2>
                <p>
                  Може да сте въвели грешен URL на страницата, а може би, е била
                  преместена в друг раздел или просто страница е временно
                  недостъпна.
                </p>
                <Link to={"/"} className="btn btn-secondary">
                  Назад към начална страница
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFound;
