import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const ScrollToTop = () => {
  const [showButton, toggleButton] = useState(false);

  useEffect(() => {
    const scrollEvent = () => {
      if (window.scrollY > 200) {
        toggleButton(true);
      } else {
        toggleButton(false);
      }
    };

    window.addEventListener("scroll", scrollEvent);

    return () => window.removeEventListener("scroll", scrollEvent);
  }, []);

  const scrollToTop = () => {
    const onScrollStep = () => {
      if (window.pageYOffset === 0) {
        clearInterval(intervalId);
      }

      window.scroll(0, window.pageYOffset - 50);
    };

    let intervalId = setInterval(onScrollStep, 30);
  };

  return (
    <React.Fragment>
      <button
        className={"scroll-top" + (showButton ? " show" : "")}
        onClick={scrollToTop}
        aria-label="Върни се в началото на страницата"
      >
        <FontAwesomeIcon icon={faChevronUp} size="1x" />
      </button>
    </React.Fragment>
  );
};

export default ScrollToTop;
