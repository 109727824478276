import React from "react";
import { Helmet } from "react-helmet";
import logoPath from "../../images/logo.png";
import AboutUsBlock from "../AboutUsBlock";
import FeaturedProjectsGrid from "../FeaturedProjectsGrid";
import Layout from "../Layout";
import ServiceGridSlider from "../ServiceGridSlider";
import Slider from "../Slider";
import TestimonialsSlider from "../TestimonialsSlider";

const HomePage = () => (
  <Layout>
    <Helmet>
      <title>
        АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и
        електроинсталации
      </title>

      <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@graph": [
            {
              "@type": "Organization",
              "@id": "https://atkom-bg.com/",
              "name": "АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации",
              "description": "АТКОМ ЕООД - изграждане и поддръжка на системи за видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации",
              "slogan": "",
              "url": "https://atkom-bg.com/",
              "logo": {
                "@type": "ImageObject",
                "url": ${logoPath},
                "width": 656,
                "height": 176,
                "caption": "АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации"
              }
            },
            {
              "@type": "WebSite",
              "@id": "https://atkom-bg.com/",
              "url": "https://atkom-bg.com/",
              "name": "АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации"
            }
          ]
        }
      `}</script>
    </Helmet>

    <Slider />
    <ServiceGridSlider />
    <AboutUsBlock />
    <FeaturedProjectsGrid />
    <TestimonialsSlider />
  </Layout>
);

export default HomePage;
