import {
  faAddressBook,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Helmet } from "react-helmet";
import logoPath from "../../images/logo.png";
import Layout from "../Layout";

const ContactPage = () => (
  <Layout>
    <Helmet>
      <title>АТКОМ ЕООД - контакти</title>

      <meta
        name="description"
        content="Контакти с АТКОМ ЕООД. Телефон: +359899777813, емайл: atkom@abv.bg, адрес: гр. Ямбол, ул. Д. Благоев 18."
      />
      <meta property="og:title" content="АТКОМ ЕООД - контакти" />
      <meta
        property="og:description"
        content="Контакти с АТКОМ ЕООД. Телефон: +359899777813, емайл: atkom@abv.bg, адрес: гр. Ямбол, ул. Д. Благоев 18."
      />
      <meta property="og:url" content="https://atkom-bg.com/contact/" />
      <link rel="shortlink" href="https://atkom-bg.com/contact/" />
      <link rel="canonical" href="https://atkom-bg.com/contact/" />

      <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@graph": [
            {
              "@type": "Organization",
              "@id": "https://atkom-bg.com/",
              "name": "АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации",
              "description": "АТКОМ ЕООД - изграждане и поддръжка на системи за видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации",
              "slogan": "",
              "url": "https://atkom-bg.com/",
              "logo": {
                "@type": "ImageObject",
                "url": ${logoPath},
                "width": 656,
                "height": 176,
                "caption": "АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации"
              }
            },
            {
              "@type": "WebPage",
              "url": "https://atkom-bg.com/contact/",
              "name": "АТКОМ ЕООД - контакти"
            }
          ]
        }
      `}</script>
    </Helmet>

    <div className="breadcrumb-area breadcrumb-bg">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="page-banner text-center">
              <h1>Контакти</h1>

              <ul className="page-breadcrumb">
                <li>
                  <a href="/">Начало</a>
                </li>
                <li>Контакти</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="page-wrapper section-space--inner--120">
      <div className="contact-section">
        <div className="container">
          <div className="row section-space--bottom--50">
            <div className="col">
              <div className="contact-map">
                <iframe
                  title="google-map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2942.2035841040265!2d26.50502231467517!3d42.48722433505539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a6343b21b91aa3%3A0x9a0bb35721f6b635!2sbul.%20%22Dimitar%20Blagoev%22%2017%D0%91%2C%208600%20Yambol%20Center%2C%20Yambol!5e0!3m2!1sen!2sbg!4v1568280348456!5m2!1sen!2sbg"
                  allowFullScreen
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="contact-information">
                <h3>Информация за контакт</h3>

                <ul>
                  <li>
                    <span className="icon">
                      <FontAwesomeIcon icon={faAddressBook} />
                    </span>
                    <span className="text">
                      <span>Ямбол, ул. Д. Благоев 18</span>
                    </span>
                  </li>

                  <li>
                    <span className="icon">
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    <span className="text">
                      <a href="tel:+359899777813">+3598 99 777 813</a>
                    </span>
                  </li>

                  <li>
                    <span className="icon">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    <span className="text">
                      <a href="mailto:atkom@abv.bg">atkom@abv.bg</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default ContactPage;
