import React from "react";
import { Helmet } from "react-helmet";
import imagePath2 from "../../images/access-control2.jpg";
import imagePath from "../../images/control-room.jpg";
import imagePath3 from "../../images/electrical.jpg";
import logoPath from "../../images/logo.png";
import Layout from "../Layout";
import ServiceGridIcon from "../ServiceGridIcon";

const AboutPage = () => (
  <Layout>
    <Helmet>
      <title>
        АТКОМ ЕООД - вашият експерт в областта на сигурността, контрола на
        достъпа и електроинсталациите
      </title>

      <meta
        name="description"
        content="АТКОМ ЕООД - доверете се на професионалистите ни при изграждане на вашите системи за сигурност, контрол, подароизвестяване и електроинсталации"
      />
      <meta
        property="og:title"
        content="АТКОМ ЕООД - вашият експерт в областта на сигурността, контрола на достъпа и електроинсталациите"
      />
      <meta
        property="og:description"
        content="АТКОМ ЕООД - доверете се на професионалистите ни при изграждане на вашите системи за сигурност, контрол, подароизвестяване и електроинсталации"
      />
      <meta property="og:url" content="https://atkom-bg.com/about-us/" />
      <link rel="shortlink" href="https://atkom-bg.com/about-us/" />
      <link rel="canonical" href="https://atkom-bg.com/about-us/" />

      <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@graph": [
            {
              "@type": "Organization",
              "@id": "https://atkom-bg.com/",
              "name": "АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации",
              "description": "АТКОМ ЕООД - изграждане и поддръжка на системи за видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации",
              "slogan": "",
              "url": "https://atkom-bg.com/",
              "logo": {
                "@type": "ImageObject",
                "url": ${logoPath},
                "width": 656,
                "height": 176,
                "caption": "АТКОМ ЕООД - видеонаблюдение, контрол на достъпа, пожароизвестяване и електроинсталации"
              }
            },
            {
              "@type": "WebPage",
              "url": "https://atkom-bg.com/about-us/",
              "name": "АТКОМ ЕООД - вашият експерт в областта на сигурността, контрола на достъпа и електроинсталациите"
            }
          ]
        }
      `}</script>
    </Helmet>

    <div className="breadcrumb-area breadcrumb-bg">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="page-banner text-center">
              <h1>За Нас</h1>

              <ul className="page-breadcrumb">
                <li>
                  <a href="/">Начало</a>
                </li>
                <li>За Нас</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="page-wrapper section-space--inner--top--120">
      <div className="about-section section-space--inner--bottom--120">
        <div className="container">
          <div className="row row-25 align-items-center">
            <div className="col-lg-6 col-12 mb-30">
              <div className="image">
                <img
                  src={imagePath}
                  className="img-fluid"
                  alt="Снимка за нас 1"
                  title="Снимка за нас 1"
                />
              </div>
            </div>

            <div className="col-lg-6 col-12 mb-30">
              <div className="content">
                <h3>Фирма АТКОМ ЕООД</h3>
                <h2>15 години лидер на пазара</h2>
                <p>
                  ATKOM ЕООД е компания с утвърдено име на пазара, която вече 15
                  години предлага на своите клиенти достъпност, надеждност и
                  сигурност в изпълнението на всички услуги. Все повече клиенти
                  се доверяват на услугите ни, привлечени от тяхното качество,
                  отзивчивост и индивидуален подход спрямо търсените решения.
                  ATKOM е синоним на отлично свършена работа, качество и
                  професионално обслужване.
                </p>
                <a
                  href="/projects"
                  className="btn btn-secondary section-space--top--20"
                >
                  Нашите Проекти
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ServiceGridIcon />

      <div className="about-section-2 section-space--inner--120">
        <div className="container">
          <div className="about-wrapper row">
            <div className="col-sm-6 col-12 order-1 order-lg-2">
              <div className="about-image about-image-1">
                <img
                  src={imagePath2}
                  alt="Снимка за нас 2"
                  title="Снимка за нас 2"
                />
              </div>
            </div>

            <div className="col-sm-6 col-12 order-2 order-lg-3">
              <div className="about-image about-image-2">
                <img
                  src={imagePath3}
                  alt="Снимка за нас 3"
                  title="Снимка за нас 3"
                />
              </div>
            </div>

            <div className="col-lg-6 col-12 order-3 order-lg-1">
              <div className="about-content about-content-1">
                <h3>Ние се грижим за вашата сигурност</h3>
                <p>
                  Следвайки актуалните тенденции в областта на системите за
                  сигурност и контрол, ние предлагаме набор от висококачествени
                  продукти и изграждаме системи от най-ново поколение и високо
                  ниво на сигурност за дома, офиса и индустрията.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-12 order-4">
              <div className="about-content about-content-2">
                <p>
                  Успехът в работата ни се дължи на наличната експертиза,
                  натрупана през годините, през които оперираме на българския
                  пазар - гарант за получаването на качествени и ефективни, от
                  гледна точка на цена и производителност, съвременни решения.
                </p>
                <a
                  href="/contact"
                  className="btn btn-secondary section-space--top--20"
                >
                  Контакти
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default AboutPage;
