import React from "react";

const Preloader = () => {
  return (
    <div className="preloader-activate preloader-active">
      <div className="preloader-area-wrap">
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
