import React, { useEffect, useRef, useState } from "react";
import NavBar from "../NavBar";
import TopBar from "../TopBar";

const Header = () => {
  const headerRef = useRef(null);
  const [sticky, toggleSticky] = useState(false);

  useEffect(() => {
    const scrollEvent = () => {
      var scroll = window.scrollY;
      var headerHeight = headerRef.current.offsetHeight;

      if (scroll < headerHeight) {
        toggleSticky(false);
      } else {
        toggleSticky(true);
      }
    };

    window.addEventListener("scroll", scrollEvent);

    return () => window.removeEventListener("scroll", scrollEvent);
  }, []);

  return (
    <div
      ref={headerRef}
      className={"header-area header-sticky" + (sticky ? " is-sticky" : "")}
    >
      <TopBar />
      <NavBar />
    </div>
  );
};

export default Header;
