import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AboutPage from "./components/AboutPage";
import ContactPage from "./components/ContactPage";
import HomePage from "./components/HomePage";
import NotFound from "./components/NotFound";
import ProjectPage from "./components/ProjectPage";
import ProjectsPage from "./components/ProjectsPage";
import ServicesPage from "./components/ServicesPage";
import "./scss/app.scss";

const App = () => (
  <Router basename={"/"}>
    <Switch>
      <Route exact path={"/"} component={HomePage} />
      <Route exact path={"/contact"} component={ContactPage} />
      <Route exact path={"/projects"} component={ProjectsPage} />
      <Route exact path={"/project/:slug"} component={ProjectPage} />
      <Route exact path={"/services"} component={ServicesPage} />
      <Route exact path={"/service/:slug"} component={ServicesPage} />
      <Route exact path={"/about-us"} component={AboutPage} />
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default App;
